const Style = ({ attributes, clientId }) => {
	const { boxPosition, width, background, padding, shadow, alignment, boxBG, boxWidth, boxHeight, boxSpace, boxBorder, boxShadow, digitTypo, digitColor, labelTypo, labelColor, sepType, sepSize, sepColor } = attributes;

	// Functions
	const parseEmtStr = (val, def) => undefined === val ? def : val;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${digitTypo?.googleFontLink ? `@import url(${digitTypo?.googleFontLink});` : ''}
		${labelTypo?.googleFontLink ? `@import url(${labelTypo?.googleFontLink});` : ''}

		#ctbCountdownTime-${clientId}{
			align-items: ${alignment};
			justify-content: ${alignment};
		}
		#ctbCountdownTime-${clientId} .countdownItems{
			gap: ${boxSpace};
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
			${background?.styles || 'background-color: #0000;'}
			padding: ${padding?.styles || '10px 15px'};
			box-shadow: ${shadow?.styles || 'none'};
			justify-content: ${boxPosition};
		}
		#ctbCountdownTime-${clientId} .countdownItems .countdownItem{
			${boxBG?.styles || `background-image: linear-gradient(135deg, #4527a4, #8344c5);`}
			width: ${boxWidth};
			height: ${boxHeight};
			${parseEmtStr(boxBorder?.styles, 'border-radius: 5%;')}
			box-shadow: ${boxShadow?.styles || 'none'};
		}
		#ctbCountdownTime-${clientId} .countdownItems .separator::before{
			content: '${sepType}';
			font-size: ${sepSize}px;
			color: ${sepColor};
		}
		#ctbCountdownTime-${clientId} .countdownItems .countdownItem .digit{
			color: ${digitColor};
			${parseEmtStr(digitTypo?.styles, 'font-size: 48px;')}
		}
		#ctbCountdownTime-${clientId} .countdownItems .countdownItem .label{
			color: ${labelColor};
			${parseEmtStr(labelTypo?.styles, 'font-size: 18px;')}
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;